import { defaultCompound } from './default-compound-uk';
import { sharedDevEuEnv } from './shared';
import { Config } from './types';

export const config: Config = {
  ...defaultCompound,
  ...sharedDevEuEnv,
  environment: 'development',
  apiUrl: 'https://api.admin.eu.dev.eucalyptus.vc',
  restApiUrl: 'https://api.admin.dev.uk.compound.co',
  assetsUrl:
    'https://storage.googleapis.com/euc-services-dev-compound-uk-assets',
  logoUrl:
    'https://storage.googleapis.com/assets-compound-uk/compound-logo.svg',
  shopifyHostname: 'dev-compound-uk.myshopify.com',
  userAppUrl: 'https://app.dev.uk.compound.co',
  doctorsAppUrl: 'https://prescribers.dev.uk.compound.co',
  googleOAuthClientId:
    '966685249037-0ikbs91jfl2gns80jlrsai618ak8smp9.apps.googleusercontent.com',
  quizAdminUrl: 'https://quiz-admin.dev.uk.compound.co',
  launchDarklyClientId: '6062ab38c8369f0e6d6c5ee4',
};
